/**
 * axios 实例
 */
import axios from 'axios';
import {MessageBox} from 'element-ui';

const service = axios.create({
    baseURL: apiURL
});

/**
 * 添加请求拦截器
 */
service.interceptors.request.use(
    (config) => {
        config.headers['Authorization'] = 'alice';
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

/**
 * 添加响应拦截器
 */
service.interceptors.response.use(
    (res) => {
        // 登录过期处理
        if (res.data?.code === 400) {
            MessageBox.alert('登录状态已过期, 请退出重新登录!', '系统提示', {
                confirmButtonText: '重新登录',
                callback: (action) => {
                    if (action === 'confirm') {
                        alert('confirm');
                    }
                },
                beforeClose: () => {
                    MessageBox.close();
                }
            }).then(r => {
            });
            return Promise.reject(new Error(res.data.message));
        }
        return res;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default service;
