<template>
  <div class="about">
    <heads></heads>
    <h1>This is an about page</h1>
    <HelloWorld msg="展示测试内容"/>
  </div>
</template>
<script>
import HelloWorld from '@/components/HelloWorld.vue'
import heads from '@/components/heads.vue'

export default {
  components: {HelloWorld, heads}
}
</script>