import Vue from 'vue'
import VueRouter from 'vue-router'
import AboutView from '@/views/AboutView.vue'
import index from '@/views/index.vue'
import ip from "@/views/ip.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: index,
        meta: {
            title: '微信域名检测',
            description: '费丽莎，微信域名检测'
        },
    },
    {
        path: '/ip',
        name: 'ip',
        component: ip,
        meta: {
            title: 'IP地址查询',
            description: '费丽莎，IP地址查询'
        }
    },
    {
        path: '/about',
        name: 'about',
        component: AboutView,
        meta: {
            title: '关于我们',
            description: '费丽莎，域名检测'
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    let description = document.querySelector('meta[name="description"]');
    description.content = to.meta.description
    let keywords = document.querySelector('meta[name="keywords"]');
    keywords.content = to.meta.description
    next()
})

export default router
