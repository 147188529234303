<template>
  <div>
    <nav>
      <router-link to="/">域名检测</router-link>
      |
      <router-link to="/ip">IP查询</router-link>
      |
      <router-link :to="{'path':'/about','query':{id:39}}">会员</router-link>
    </nav>
  </div>
</template>

<script>
export default {
  methods: {}
}

</script>

<style>
nav {
  padding: 30px;
  font-size: 22px;

  a {
    font-weight: bold;
    color: #2c3e50;
    text-decoration: none;
    margin: 0 10px;

    &.router-link-exact-active {
      color: #609af4;
    }
  }

  a:active {
    color: red; /* 你可以将 red 替换为你想要的颜色 */
  }
}
</style>