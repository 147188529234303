import request from '@/api/request';

/**
 * 请求数据GET
 */
export async function getData() {
    const res = await request.get('/web/getData');
    if (res.data.code === 200) {
        return res.data.data;
    }
    return Promise.reject(new Error(res.data.message));
}

/**
 * 获取IP地理位置
 */
export async function getIpLocation(data) {
    const res = await request.post('/web/getIpLocation', data);
    if (res.data.code === 200) {
        return res.data;
    }
    return Promise.reject(new Error(res.data.message));
}

/**
 * 请求查询域名状态
 */
export async function checkDomain(data) {
    const res = await request.post('/web/checkWxDomain', data);
    if (res.data.code !== 0) {
        return res.data;
    }
    return Promise.reject(new Error(res.data.message));
}

