<template>
  <div class="body-container">
    <heads></heads>
    <div class="text-center">
      <h2>IP查询，快速精准，更新快</h2>
      <div style="min-height: 120px;">
        <p class="ip_text">{{ res_ip }}</p>
        <p class="ip_text">{{ area }}</p>
        <p class="ip_text">{{ ips }}</p>
      </div>
    </div>

    <div class="inputDiv">
      <el-input
          class="inputDomain"
          placeholder="请输入查询的IP"
          v-model="ip"
          clearable>
      </el-input>
      <el-button type="primary" icon="el-icon-search" @click="getIpLocation" :loading="loading">查询</el-button>
      <h3 v-if="loading" style="margin-top: 25px;">Loading...</h3>
      <div v-if="title" style="margin-top: 25px;">
        <h3 :style="{'color': titleColour}">{{ title }}</h3>
      </div>
    </div>

    <div class="text-center">
      <h2>多种请求模式</h2>
      <p>API接口GET支持，提交方式自由选择</p>
    </div>

    <div class="text-center">
      <h2>毫秒级API响应</h2>
      <p>支持高并发请求，毫秒级返回检测结果</p>
    </div>

  </div>
</template>

<script>
import heads from '@/components/heads.vue'
import {getIpLocation} from "@/api";

export default {
  name: 'index',
  components: {
    heads
  },
  data() {
    return {
      title: '',
      loading: false,
      ip: '',
      res_ip: '位置获取中....',
      area: '',
      ips: '',
      titleColour: ''
    };
  },
  mounted() {
    this.autoIpLocation()
  },
  methods: {
    //获取访客IP位置
    autoIpLocation() {
      let data = {ip: ""};
      getIpLocation(data)
          .then((data) => {
            this.loading = false;
            if (data.code === 200) {
              this.res_ip = data.data.ip;
              this.area = data.data.area;
              this.ips = data.data.ips;
            } else {
              this.res_ip = data.message;
            }
          })
          .catch((e) => {
            this.loading = false;
            this.$message.error(e.message);
          });
    },
    //请求获取IP位置
    getIpLocation() {
      if (this.ip) {
        if (this.isValidIPv4Address(this.ip)) {
          this.res_ip = '位置获取中....';
          this.area = '';
          this.ips = '';
          this.loading = true;
          let data = {ip: this.ip};
          getIpLocation(data)
              .then((data) => {
                this.loading = false;
                if (data.code === 200) {
                  this.res_ip = data.data.ip;
                  this.area = data.data.area;
                  this.ips = data.data.ips;
                } else {
                  this.res_ip = data.message;
                }
              })
              .catch((e) => {
                this.loading = false;
                this.$message.error(e.message);
              });
        } else {
          this.$message.error('IP格式不正确');
        }
      } else {
        this.$message.error('请输入查询IP');
      }
    },
    //判断是否正确IP格式
    isValidIPv4Address(ip) {
      const ipv4Pattern = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
      return ipv4Pattern.test(ip);
    }
  }
}
</script>

<style>
.body-container {
  padding-bottom: 1px;
}

.text-center {
  @media screen and (min-width: 1024px) {
    width: 81%;
  }
  width: 95%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 5px;
  margin: 0 auto 20px auto;

  p {
    line-height: 28px;
  }

  .ip_text {
    padding: 0 10px 0 10px;
    margin: 10px 0;
    line-height: 28px;
    color: #0da018;
  }
}

.inputDiv {
  @media screen and (min-width: 1024px) {
    width: 79%;
  }
  width: 91%;
  margin: 0 auto 20px auto;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 5px;
}

.inputDomain {
  @media screen and (min-width: 1024px) {
    width: 78% !important;
  }
  width: 69% !important;
  margin-right: 10px;
}

.el-select .el-input {
  width: 130px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.logo {
  width: 200px;
  margin-bottom: 20px;
}

.el-col {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.grid-content {
  @media screen and (min-width: 1024px) {
    width: 83%;
  }
  width: 97%;
  min-height: 80px;
  background: #f5f5f5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  display: flex; /* 弹性布局 */
  align-items: center; /* 垂直居中对齐 */

  ul {
    padding: 0 5px 0 15px;
  }

  li {
    list-style-type: none;
  }

  img {
    @media screen and (min-width: 1024px) {
      width: 72px;
    }
    width: 100px;
  }

  .text {
    flex: 1;
    font-size: 16px;
    text-align: left;
    line-height: 26px;
  }
}
</style>


