<template>
  <div class="body-container">
    <heads></heads>
    <div class="text-center">
      <!--    <img alt="Vue logo" src="../assets/o.png" class="logo">-->
      <h2>专业的域名查询，快速精准</h2>
      <p>实时查询域名状态，从多维度、高并发检验域名</p>
    </div>

    <div class="inputDiv">
      <el-input
          class="inputDomain"
          placeholder="请输入查询的域名"
          v-model="domain"
          clearable>
      </el-input>
      <el-button type="primary" icon="el-icon-search" @click="checkDomain" :loading="loading">查询</el-button>
      <h3 v-if="loading" style="margin-top: 25px;">Loading...</h3>
      <div v-if="title" style="margin-top: 25px;">
        <h3 :style="{'color': titleColour}">{{ title }}</h3>
      </div>
    </div>

    <el-row :gutter="10">
      <el-col :xs="24" :lg="24">
        <div class="grid-content">
          <div class="img">
            <ul>
              <li><img src="../assets/wx.jpeg" alt=""></li>
            </ul>
          </div>
          <div class="text">
            <ul>
              <li><h2>微信网址安全</h2></li>
              <li>实时检测域名是否被微信拦截，并发能力强，准确率高</li>
            </ul>
          </div>
        </div>
      </el-col>
    </el-row>

    <div class="text-center">
      <h2>多种请求模式</h2>
      <p>API接口GET支持，提交方式自由选择</p>
    </div>

    <div class="text-center">
      <h2>毫秒级API响应</h2>
      <p>实时检测域名是否拦截，毫秒级返回检测结果</p>
    </div>

  </div>
</template>

<script>
import heads from '@/components/heads.vue'
import {checkDomain} from "@/api";

export default {
  name: 'index',
  components: {
    heads
  },
  data() {
    return {
      title: '',
      loading: false,
      domain: '',
      titleColour: ''
    };
  },
  mounted() {
  },
  methods: {
    //请求检测域名
    checkDomain() {
      if (this.domain) {
        if (this.isValidDomain(this.domain)) {
          this.title = '';
          this.loading = true;
          let data = {domain: this.domain};
          checkDomain(data)
              .then((data) => {
                this.loading = false;
                this.title = data.message;
                if (data.code === 200) {
                  this.titleColour = '#67c23a';
                  this.$message.success(data.message);
                } else {
                  this.titleColour = '#f5222d';
                  this.$message.error(data.message);
                }
              })
              .catch((e) => {
                this.loading = false;
                this.$message.error(e.message);
              });
        } else {
          this.$message.error('域名格式不正确');
        }
      } else {
        this.$message.error('请输入查询域名');
      }
    },
    //判断是否正确域名格式
    isValidDomain(domain) {
      let arr = domain.split('//');
      console.log(arr);
      let resDomain;
      if (arr[1]) {
        resDomain = arr[1];
      } else {
        resDomain = arr[0];
      }
      const regex = /^([a-z0-9]([a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/i;
      return regex.test(resDomain);
    }
  }
}
</script>

<style>
.body-container {
  padding-bottom: 1px;
}

.text-center {
  @media screen and (min-width: 1024px) {
    width: 81%;
  }
  width: 95%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 5px;
  margin: 0 auto 20px auto;

  p {
    line-height: 28px;
  }
}

.inputDiv {
  @media screen and (min-width: 1024px) {
    width: 79%;
  }
  width: 91%;
  margin: 0 auto 20px auto;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 5px;
}

.inputDomain {
  @media screen and (min-width: 1024px) {
    width: 78% !important;
  }
  width: 69% !important;
  margin-right: 10px;
}

.el-select .el-input {
  width: 130px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.logo {
  width: 200px;
  margin-bottom: 20px;
}

.el-col {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.grid-content {
  @media screen and (min-width: 1024px) {
    width: 83%;
  }
  width: 97%;
  min-height: 80px;
  background: #f5f5f5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  display: flex; /* 弹性布局 */
  align-items: center; /* 垂直居中对齐 */

  ul {
    padding: 0 5px 0 15px;
  }

  li {
    list-style-type: none;
  }

  img {
    @media screen and (min-width: 1024px) {
      width: 72px;
    }
    width: 100px;
  }

  .text {
    flex: 1;
    font-size: 16px;
    text-align: left;
    line-height: 26px;
  }
}
</style>


